/* eslint-disable no-console */
export class CCLogger {
  static isLogsEnabled() {
    const isDebugMode = localStorage.getItem('isDebugMode') === 'true';
    return process.env.NODE_ENV !== 'production' || isDebugMode;
  }

  static actionCable(...messages) {
    if (this.isLogsEnabled()) {
      console.log(`%c[Action Cable]: `, 'color: #039dad;', ...messages);
    }
  }

  static development(...messages) {
    if (this.isLogsEnabled()) {
      console.log(`%c[TEST]: `, 'color: #8573e7;', ...messages);
    }
  }

  static analyticsEvents(...messages) {
    if (this.isLogsEnabled()) {
      console.log(`%c[ANALYTICS EVENTS]: `, 'color: #ffd75b;', ...messages);
    }
  }

  static frontendEvents(...messages) {
    if (this.isLogsEnabled()) {
      console.log(`%c[FRONTEND EVENTS]: `, 'color: #49b77d;', ...messages);
    }
  }

  static kratosEvents(...messages) {
    if (this.isLogsEnabled()) {
      console.log(`%c[KRATOS SESSION]: `, 'color: #FF5630;', ...messages);
    }
  }

  static table(message) {
    if (this.isLogsEnabled()) {
      console.table({
        'Time Stamp': new Date(),
        message,
      });
    }
  }
}
