<template>
  <div>
    <woot-button
      size="small"
      variant="smooth"
      color-scheme="secondary"
      icon="video-add"
      class="join-call-button"
      :is-loading="isLoading"
      @click="joinTheCall"
    >
      {{ $t('INTEGRATION_SETTINGS.DYTE.CLICK_HERE_TO_JOIN') }}
    </woot-button>
    <div v-if="dyteAuthToken" class="video-call--container">
      <iframe
        :src="meetingLink"
        allow="camera;microphone;fullscreen;display-capture;picture-in-picture;clipboard-write;"
      />
      <woot-button
        size="small"
        variant="smooth"
        color-scheme="secondary"
        class="join-call-button"
        @click="leaveTheRoom"
      >
        {{ $t('INTEGRATION_SETTINGS.DYTE.LEAVE_THE_ROOM') }}
      </woot-button>
    </div>
  </div>
</template>
<script>
import DyteAPI from 'dashboard/api/integrations/dyte';
import { buildDyteURL } from 'shared/helpers/IntegrationHelper';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    messageId: {
      type: Number,
      required: true,
    },
    meetingData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return { isLoading: false, dyteAuthToken: '', isSDKMounted: false };
  },
  computed: {
    meetingLink() {
      return buildDyteURL(this.meetingData.meeting_id, this.dyteAuthToken);
    },
  },
  watch: {
    dyteAuthToken(newValue) {
      const appBar = document.getElementsByClassName('c-app-bar')?.[0];
      if (!appBar) {
        return;
      }
      if (newValue) {
        appBar.style.pointerEvents = 'none';
      } else {
        appBar.style.removeProperty('pointer-events');
      }
    },
  },
  methods: {
    hideElement(element) {
      const elementSelector = document.getElementsByClassName(element)?.[0];
      if (elementSelector) {
        elementSelector.style.display = 'none';
      }
    },
    showElement(element) {
      const elementSelector = document.getElementsByClassName(element)?.[0];
      if (elementSelector) {
        elementSelector.style.removeProperty('display');
      }
    },
    async joinTheCall() {
      this.isLoading = true;
      this.hideElement('woot--bubble-holder');
      try {
        const { data: { token } = {} } = await DyteAPI.addParticipantToMeeting(
          this.messageId
        );
        this.dyteAuthToken = token;
      } catch (err) {
        this.showAlert(this.$t('INTEGRATION_SETTINGS.DYTE.JOIN_ERROR'));
      } finally {
        this.isLoading = false;
      }
    },
    leaveTheRoom() {
      this.showElement('woot--bubble-holder');
      this.dyteAuthToken = '';
    },
  },
};
</script>
<style lang="scss">
.join-call-button {
  margin: var(--space-small) 0;
}

.video-call--container {
  position: fixed;
  top: 72px;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-high);
  padding: var(--space-smaller);
  background: var(--b-800);

  iframe {
    width: 100%;
    height: calc(100% - 72px);
    border: 0;
  }

  button {
    position: absolute;
    top: var(--space-smaller);
    right: 10rem;
  }
}
</style>
