import { urqlClient } from '@pypestream/design-system';
import { BUS_EVENTS } from '../../../shared/constants/busEvents';
import { kratosApi } from '../../api/kratos';
import store from '../../store';
import types from '../../store/mutation-types';
import { CCLogger } from '../logger';

export const CHECKS_SESSION_HEALTH_INTERVAL = 5 * 60 * 1000;

export const checkSessionHealth = async () => {
  kratosApi
    .toSession()
    .then(async ({ data: session }) => {
      store.commit(types.SET_SESSION, session);
      const user = await (await urqlClient()).getUserInfoLimited();
      CCLogger.kratosEvents('Session health check success', session, user);
    })
    .catch(e => {
      CCLogger.kratosEvents('Cant fetch session', e.response);
      if (e.response?.status === 401 || e.response?.status === 403) {
        bus.$emit(BUS_EVENTS.SESSION_EXPIRED);
      }
    });
};
