<template>
  <div class="c-conversation-summary">
    <woot-button
      v-if="!summary"
      size="small"
      variant="link"
      icon="add"
      @click="openModal"
    >
      Add summary
    </woot-button>
    <div
      v-if="summary"
      class="c-conversation-summary__content"
      @click="openModal"
    >
      <h4 class="c-conversation-summary__title">Summary</h4>
      <div
        v-dompurify-html="formatedMessage"
        class="c-conversation-summary__message"
      />
    </div>
    <woot-modal
      :show="showConverstionSummaryModal"
      :on-close="closeModal"
      size="c-conversation-summary__modal"
    >
      <woot-modal-header
        header-title="Conversation Summary"
        class="c-conversation-summary__modal-header"
      />
      <div class="c-conversation-summary__modal-content">
        <div class="c-conversation-summary__editor-wrapper">
          <woot-message-editor
            v-model="message"
            :min-height="4"
            :is-summary="true"
            class="summary-editor"
          />
        </div>
      </div>
      <div
        class="c-conversation-summary__footer"
        :class="{ 'c-conversation-summary__footer--simple': !hookId }"
      >
        <button
          v-if="hookId"
          class="c-conversation-summary__footer-icon-wrapper"
          :disabled="isLoading"
          @click="generateSummary"
        >
          <fluent-icon icon="bot" :size="24" />
        </button>
        <woot-button :is-loading="isLoading" @click="updateSummary">
          Save
        </woot-button>
      </div>
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import OpenAPI from 'dashboard/api/integrations/openapi';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';

export default {
  components: {
    WootMessageEditor,
  },
  mixins: [alertMixin, messageFormatterMixin],
  props: {
    contactId: { type: Number, default: null },
    conversationId: {
      type: Number,
      default: 0,
    },
    summary: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showAttributeDropDown: false,
      showConverstionSummaryModal: false,
      message: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({ appIntegrations: 'integrations/getAppIntegrations' }),
    hookId() {
      return this.appIntegrations.find(
        integration => integration.id === 'openai' && !!integration.hooks.length
      )?.hooks[0]?.id;
    },
    formatedMessage() {
      return this.formatMessage(this.summary, false, false);
    },
  },
  methods: {
    openModal() {
      const { summary } = this;
      if (summary) this.message = summary;
      this.showConverstionSummaryModal = true;
    },
    closeModal() {
      this.message = '';
      this.showConverstionSummaryModal = false;
    },
    async generateSummary() {
      this.isLoading = true;

      try {
        const result = await OpenAPI.processEvent({
          hookId: this.hookId,
          type: 'summarize',
          content: this.message,
          tone: 'professional',
          conversationId: this.conversationId,
        });
        const {
          data: { message: generatedMessage, response_code: responseCode },
        } = result;
        if (responseCode === 200) {
          this.message = generatedMessage;
          this.showAlert('Summary generated successfully');
        } else {
          this.showAlert(
            `Unable to gather AI summary of conversation: ${result.data.message}`
          );
        }
      } catch (error) {
        this.showAlert(this.$t('INTEGRATION_SETTINGS.OPEN_AI.GENERATE_ERROR'));
      } finally {
        this.isLoading = false;
      }
    },
    updateSummary() {
      const { message, conversationId } = this;
      this.isLoading = true;
      this.$store
        .dispatch('updateSummary', {
          conversationId,
          summary: message,
        })
        .then(() => {
          this.$store.dispatch('markMessagesRead', { id: conversationId });
          this.isLoading = false;
          this.message = '';
          this.closeModal();
          this.showAlert('Summary updated successfully');
        });
    },
  },
};
</script>

<style lang="scss">
.c-conversation-summary {
  width: 100%;
  padding: var(--space-slab) var(--space-normal);

  &__content {
    cursor: pointer;
  }

  &__title {
    font-size: var(--font-size-small);
  }
  &__message {
    & > * {
      width: 100%;
      word-wrap: break-word;
      display: inline-block;
    }
  }
  &__modal {
    height: 75vh;
    display: flex;
    flex-direction: column;
  }

  &__modal-header {
    flex: 0;
  }

  &__modal-content {
    margin: 0 var(--space-large);
    flex: 1;
    border: 1px solid var(--pype-grey-100);
    height: 75%;
    border-radius: var(--border-radius-large);
  }

  &__editor-wrapper {
    padding: var(--space-normal);
    height: 100%;

    & .ProseMirror ul,
    .ProseMirror ol {
      padding-left: var(--space-large);
    }
  }

  &__textarea {
    padding: var(--space-large);
    margin: 0;
    height: 100% !important;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-normal) var(--space-large);

    &--simple {
      justify-content: flex-end;
    }
  }

  &__footer-icon-wrapper {
    background-color: var(--pype-grey-100);
    height: var(--space-larger);
    width: var(--space-larger);
    border: none;
    border-radius: var(--border-radius-normal);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--pype-grey-200);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  & .ProseMirror-woot-style {
    min-height: 8rem;
    max-height: initial;
    height: 100%;
  }
}
.summary-editor {
  .ProseMirror-woot-style {
    // that calculation needed because i need to control height of library component without overflow hidden to render canned response on the top of block
    max-height: calc(75vh - 76px - 72px - 80px);
  }
}
</style>
